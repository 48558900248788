// !Headline Competition
$(document).ready(function () {
    var owlArticles = $(".articles-carousel");
    owlArticles.owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        dots: true,
        lazyLoad: true,
        nav: false,
        items: 1,
        margin: 23,
        responsive: {
            0: {
                items: 1,
            },
            552: {
                items: 1,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                loop: true,
                dots: true,
                stagePadding: 0,
                items: 3,
            },
        },
    });

    // owlArticles.on("mousewheel", ".owl-stage", function (e) {
    //     if (e.deltaY > 0) {
    //         owlArticles.trigger("prev.owl");
    //     } else {
    //         owlArticles.trigger("next.owl");
    //     }
    //     e.preventDefault();
    // });

    var owlSponsor = $(".sponsor-carousel");
    owlSponsor.owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        dots: false,
        lazyLoad: true,
        nav: false,
        items: 1,
        margin: 23,
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            960: {
                items: 5,
            },
            1200: {
                items: 6,
            },
        },
    });
    owlSponsor.on("mousewheel", ".owl-stage", function (e) {
        if (e.deltaY > 0) {
            owlSponsor.trigger("next.owl");
        } else {
            owlSponsor.trigger("prev.owl");
        }
        e.preventDefault();
    });
});
