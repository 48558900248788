require("./bootstrap");
require("owl.carousel");
require("./carousel");
require("./custom");
require("datatables.net-bs4/js/dataTables.bootstrap4");
require("./popper");
window.WOW = require("wowjs").WOW;

// DataTables
$(document).ready(function () {
    $("#dataTables").DataTable();
});
$(document).ready(function () {
    $("#recycleBin").DataTable();
});

$(document).ready(function () {
    $(".request-tables").DataTable();
});

$(document).ready(function () {
    $(".dataTables").DataTable();
});

// Clickable DataTables
$(document).ready(function () {
    var table = $("table.request-tables").DataTable();

    var prevent = false;

    $(".btn-delete").click(function () {
        prevent = true;
    });
    $(".btn-restore").click(function () {
        prevent = true;
    });
    $(".completed_tr").click(function () {
        prevent = true;
    });
    $(".deleted_tr").click(function () {
        prevent = true;
    });
    
    $(".request-tables").on("click", "tbody tr", function () {
        if (prevent == true) {
            prevent = false;
            return;
        }
        if (!table.row(this).data()) {
            return;
        }
        window.location.href = $(this).data("href");
    });

    $("#result_link").click(function() {
        var copyText = $("#result").val();
        // alert(copyText);
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    });
    
});

// Copy value
$(document).ready(function () {
    $("#copy_detail").click(function () {
        $("#detail_file").select();
        document.execCommand("copy");
        $("#iconcpy1").removeClass("far fa-clone").addClass("fas fa-clone");
    })

    $("#copy_res").click(function () {
        $("#result").select();
        document.execCommand("copy");
        $("#iconcpy2").removeClass("far fa-clone").addClass("fas fa-clone");
    });
});

// BS 5 Tooltip
$(function () {
    $('[data-bs-toggle="tooltip"]').tooltip();
})

// Preloader
setTimeout(function () {
    $(".preloader").fadeToggle();
}, 1000);

// Disable Inspect Elements
window.oncontextmenu = function () {
    return false;
};
$(document).keydown(function (event) {
    if (event.keyCode == 123) {
        return false;
    } else if (
        (event.ctrlKey && event.shiftKey && event.keyCode == 73) ||
        (event.ctrlKey && event.shiftKey && event.keyCode == 74)
    ) {
        return false;
    }
});