$(document).ready(function () {
    // Disable before submit
    $(window).on("beforeunload", function () {
        $(".btnSubmit").prop("disabled", true);
        $(".btnSubmit").html("Please wait for a second");
    });

    //  Sidebar
    if ($(window).width() < 1200) {
        var trigger = $(".page-content");

        trigger.click(function () {
            $("#toggled").removeClass("toggled");
        });
    } else {
        $("#toggled").addClass("toggled");
    }

    $("#close-sidebar").click(function () {
        $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function () {
        $(".page-wrapper").addClass("toggled");
    });

    $(".sidebar-dropdown > a").click(function () {
        $(".sidebar-submenu").slideUp(200);
        if ($(this).parent().hasClass("active")) {
            $(".sidebar-dropdown").removeClass("active");
            $(this).parent().removeClass("active");
        } else {
            $(".sidebar-dropdown").removeClass("active");
            $(this).next(".sidebar-submenu").slideDown(200);
            $(this).parent().addClass("active");
        }
    });

    // Show Modal
    $("#join_group").modal({
        backdrop: "static",
        keyboard: false,
    });
    $("#join_group").modal("show");

    $("#success_compete").modal({
        backdrop: "static",
        keyboard: false,
    });
    $("#success_compete").modal("show");

    $("#alert").modal();
    $("#alert").modal("show");

    // change header color for mobile
    var maxWidth = window.matchMedia("(max-width: 992px)");
    if (maxWidth.matches) {
        $(".navChange").addClass("bg-light");
        $(".navbar-brand").removeClass("logoWhite");
        $(".navChange a").css("color", "black");
        $(".navChange a").hover(
            function () {
                $(this).css("color", "white");
            },
            function () {
                $(this).css("color", "black");
            }
        );
        
    } else {
        if($(this).scrollTop() > 50){
            $(".navbar-brand").removeClass("logoWhite");
        } else {
            $(".navChange").removeClass("bg-light");
            $(".navbar-brand").addClass("logoWhite");
        }
        
    }
    
});

$(window).scroll(function () {
    var maxWidth = window.matchMedia("(max-width: 992px)");
    $(".navChange").toggleClass("scrolled", $(this).scrollTop() > 50);

    if ($(this).scrollTop() > 50) {
        $(".navbar-brand").removeClass("logoWhite");
    } else {
        if (!maxWidth.matches) {
            $(".navbar-brand").addClass("logoWhite");
        }
    }

    var scrollPos = 0;
    scrollPos = $(this).scrollTop();
    if (scrollPos > 50) {
        $(".navChange a").css("color", "black");
        $(".navChange a").hover(
            function () {
                $(this).css("color", "white");
            },
            function () {
                $(this).css("color", "black");
            }
        );
    } else {
        if (maxWidth.matches) {
            $(".navChange a").css("color", "black");
            $(".navChange a").hover(
                function () {
                    $(this).css("color", "white");
                },
                function () {
                    $(this).css("color", "black");
                }
            );
        } else {
            $(".navChange a").hover(
                function () {
                    $(this).css("color", "white");
                },
                function () {
                    $(this).css("color", "white");
                }
            );
            $(".navChange").removeClass("bg-light");
            $(".navbar-brand").addClass("logoWhite");
            $(".navChange").css("background", "transparent");
        }

        if (maxWidth.matches) {
            $(".navChange a").css("color", "black");
        } else {
            $(".navChange a").css("color", "white");
        }
    }
});

// responsive - screen resizing
$(window).on("resize", function () {
    var maxWidth = window.matchMedia("(max-width: 992px)");
    if (maxWidth.matches || $(this).scrollTop() > 50) {
        $(".navChange").addClass("bg-light");
        $(".navbar-brand").removeClass("logoWhite");
        $(".navChange a").css("color", "black");
        $(".navChange a").hover(
            function () {
                $(this).css("color", "white");
            },
            function () {
                $(this).css("color", "black");
            }
        );
    } else {
        $(".navChange").removeClass("bg-light");
        $(".navbar-brand").addClass("logoWhite");
        $(".navChange a").css("color", "white");
    }
});

// Scroll back to Top
$(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
        $("#scrollUp").css({
            opacity: "1",
            "pointer-events": "auto",
        });
        // $("#scrollUp").show();
    } else {
        // $("#scrollUp").hide();
        $("#scrollUp").css({
            opacity: "0",
            "pointer-events": "none",
        });
    }
});
$(document).ready(function () {
    $("#scrollUp").click(function () {
        $("body, html").animate({ scrollTop: 0 }, 100);
        return false;
    });
});